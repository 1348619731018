/* eslint-disable react-hooks/exhaustive-deps */
import { useOneTapProvider } from "../utils/Globals/CustomHooks"
import { useEffect, useState } from "react";
import { sphereoneSdk } from "../utils/SphereOne";
import { SupportedChains } from "websdk";
import { useNavigate } from 'react-router-dom';
import NFTCard from "../components/Nfts";
import ErrorShowing from "../components/ErrorShowing";
import Close from "../assets/svg/Close";
import TreasureButton from "../assets/svg/treasureButton"
import Wallet from "../assets/svg/Wallet";
import LoadingSVG from "../assets/svg/Loading";
import Transfer from "../assets/svg/Transfer";
import InstructionsOverlay from "../components/InstructionsOverlay";
import { ImmutableXClient } from "@imtbl/imx-sdk";
import Footer from '../components/Footer';

// import { redirect } from "react-router-dom";
const Assets = () => {

  const navigate = useNavigate()
  const { filteredWallets, isSphereOneConnected, isEthereumConnected } = useOneTapProvider();
  const [assets, setAssets] = useState([])
  const [showWidget, setShowWidget] = useState(false);
  const [copied, setCopied] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [notImportedWallet, setNotImportedWallet] = useState();
  const [selected, setSelected] = useState([]);
  const [showTransferPopup, setShowTransferPopup] = useState(false);
  const [destinationAddress, setDestinationAddress] = useState("");
  const [errorMsg, setErrorMsg] = useState(null);
  const [showInstructions, setShowInstructions] = useState(isSphereOneConnected)
  const [showLootboxButton, setShowLootboxButton] = useState(false)

  const handleWalletClick = () => {
    setShowWidget(!showWidget);
  };

  const copyToClipboard = () => {
    navigator.clipboard.writeText(notImportedWallet.address);
    setCopied(true);

    setTimeout(() => {
      setCopied(false);
    }, 5000);
  };

  const transferItemsToAddress = async (destinationAddress) => {
    setShowTransferPopup(false);
    const successfulTransfers = []
    const array = [];
    const nfts = [];
    selected.forEach((element) => {
      array.push(assets[element].token_id);
      nfts.push({
        tokenId: assets[element].token_id,
        tokenAddress: assets[element].token_address || assets[element].address,
        toAddress: destinationAddress,
      });
    });
    for (const element of nfts) {
      const addressAsset = assets.find(item => item.token_id === element.tokenId);
      try {
        const responseTransfer = await sphereoneSdk.transferNft({
          chain: SupportedChains.IMMUTABLE,
          fromAddress: addressAsset.user,
          toAddress: element.toAddress,
          nftTokenAddress: element.tokenAddress,
          tokenId: element.tokenId,
          reason: "Transfer Assets"
        });

        if (responseTransfer.data) {
          successfulTransfers.push(element);
        } else if (responseTransfer.error) {
          if (responseTransfer.error.slice(-16) === "' does not exist" && responseTransfer.error.slice(0, 64) === "Something went wrong sending NFT on Immutable X, error: receiver") {
            setErrorMsg("The receiver address does not exists");
          } else {
            setErrorMsg("Something went wrong. Please contact us at info@qorgaming.com");
            console.error("Res: ", responseTransfer.error);
          }
          return;
        }
      } catch (e) {
        if (e.message === "Error: There was an error getting the wrapped dek") {
          setErrorMsg("You need to input a valid PIN");
        } else {
          setErrorMsg("Token transfer went wrong: ", e);
        }
        return;
      }
    }
    if (successfulTransfers.length === nfts.length) {
      setTimeout(() => {
        window.location.reload();
      }, 5000);
      setErrorMsg("Transfer Success")
    }
  }

  const handleLootBoxes = (skinAssets) => {//This part filters the lootboxes to know if the user has any, if he does, he will see a button to open it
    const lootBoxes = skinAssets.filter((skin) =>
      ["0x8a2315e441786e10ff1962387407628108b9ba33"].includes(skin.token_address) &&
      skin.name.startsWith("One Tap Loot Box")
    );
    if (lootBoxes.length > 0) {
      setShowLootboxButton(true);
      const lootBoxesJSON = JSON.stringify(lootBoxes);
      localStorage.setItem('lootboxes', lootBoxesJSON);
    }
  }

  const handleTransferClick = () => {
    sphereoneSdk.openPinCode("SEND_NFT");
    setShowTransferPopup(true);
  };
  const getAssetsFromIMX = async (wallet_address) => {
    // ["0xeea2fe42cc1774a802edfc7a08a35c361827befc", "0x8a2315e441786e10ff1962387407628108b9ba33", "0x938b5c1850106ebc82d49af65a030e1d9083f2f5"]
    const client = await ImmutableXClient.build({
      publicApiUrl: "https://api.x.immutable.com/v1",
    });
    if (wallet_address !== undefined) {
      const petsAssetsRequest = await client.getAssets({
        user: wallet_address,
        collection: "0xeea2fe42cc1774a802edfc7a08a35c361827befc",
      });
      const skinsAssetsRequest = await client.getAssets({
        user: wallet_address,
        collection: "0x8a2315e441786e10ff1962387407628108b9ba33",
      });
      const badgesAssetsRequest = await client.getAssets({
        user: wallet_address,
        collection: "0x938b5c1850106ebc82d49af65a030e1d9083f2f5",
      });
      const mixedAssets = [...petsAssetsRequest.result, ...skinsAssetsRequest.result, ...badgesAssetsRequest.result]
      return mixedAssets;
    }
  };

  useEffect(() => {
    if (isSphereOneConnected) {
      setNotImportedWallet(filteredWallets.filter(wallet => wallet.isImported === false)[0]);
      sphereoneSdk.pinCodeHandler();
      const fetchAssetsPromises = filteredWallets.map(walletAddress =>
        getAssetsFromIMX(walletAddress.address)
      );
      Promise.all(fetchAssetsPromises)
        .then(assetsArrays => {
          const combinedAssets = assetsArrays.flat();
          handleLootBoxes(combinedAssets)
          setAssets(combinedAssets);
        })
    } else if (isEthereumConnected && assets.length === 0) {
      const addressLocalStorage = filteredWallets[0];
      if (addressLocalStorage !== undefined && addressLocalStorage !== null) {
        console.log(addressLocalStorage);
        getAssetsFromIMX(filteredWallets[0])
          .then((assets) => {
            handleLootBoxes(assets)
            setAssets(assets);
          })
      }
    }
    setIsLoading(false)
  }, [])
  return (
    <>
      {showInstructions ?
        <InstructionsOverlay closeInstructions={() => setShowInstructions(false)}></InstructionsOverlay>
        :
        <div className="banner relative section">
          <div className="container">
            <div style={{
              position: "fixed",
              bottom: "5%",
              right: "5%",
              display: "flex",
              flexDirection: "row",
              alignItems: "flex-end",
              zIndex: 9999
            }}>
              {showWidget && (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  {notImportedWallet && (
                    <div
                      style={{
                        border: '2px solid #BB256E',
                        borderRadius: '1rem',
                        padding: '10px',
                        cursor: 'pointer',
                        color: '#fff'
                      }}
                      onClick={copyToClipboard}
                    >
                      <p style={{ margin: 0 }}>{notImportedWallet.address.slice(0, 8) + "..." + notImportedWallet.address.slice(-8)}</p>
                      In-Game Wallet {copied && <span style={{ color: 'green', marginLeft: '5px' }}>Copied!</span>}
                    </div>
                  )}
                  <a
                    href="https://wallet.sphereone.xyz/LinkedAccounts"
                    rel="noreferrer"
                    target="_blank"
                    style={{
                      display: "inline-block",
                      padding: "10px 20px",
                      backgroundColor: "#BB256E",
                      color: "white",
                      textDecoration: "none",
                      border: "2px solid black",
                      borderRadius: "1rem",
                      fontSize: "20px",
                      marginTop: "15px",
                      textAlign: "center",
                    }}
                  >
                    Check All Wallets
                  </a>
                </div>
              )}
              {isSphereOneConnected && <div
                style={{ width: "50px", height: "50px", backgroundColor: "#BB256E", borderRadius: "50%", display: "flex", alignItems: "center", justifyContent: "center", cursor: "pointer", marginLeft: "10px", }}
                onClick={handleWalletClick}
              >
                {showWidget ? <Close /> : <Wallet />}
              </div>}
              {showLootboxButton && <div
                style={{ width: "50px", height: "50px", backgroundColor: "#BB256E", borderRadius: "50%", display: "flex", alignItems: "center", justifyContent: "center", cursor: "pointer", marginLeft: "10px", }}
                onClick={() => navigate('/openlootbox')}
              >
                <TreasureButton></TreasureButton>
              </div>}
              {selected.length > 0 && <div
                style={{ width: "50px", height: "50px", backgroundColor: "#BB256E", borderRadius: "50%", display: "flex", alignItems: "center", justifyContent: "center", cursor: "pointer", marginLeft: "10px", }}
                onClick={handleTransferClick}
              >
                <Transfer></Transfer>
              </div>}
            </div>
            {errorMsg && <ErrorShowing errorText={errorMsg} />}
            {showTransferPopup ? (
              <div style={{ position: 'fixed', top: 0, left: 0, width: '100%', height: '100%', background: 'rgba(0, 0, 0, 0.7)', display: 'flex', justifyContent: 'center', alignItems: 'center', zIndex: 9999, }}>
                <div style={{ background: 'black', padding: '20px', borderRadius: '10px', textAlign: 'center', boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.5)', }} >
                  <p style={{ fontSize: '24px', color: 'white' }}>Confirmation Required</p>
                  <p style={{ fontSize: '16px', color: 'grey' }}>Are you sure you want to transfer your assets? If so, please input the recipient's address.</p>
                  <input
                    style={{
                      width: '100%',
                      padding: '10px',
                      margin: '10px 0',
                      borderRadius: '5px',
                    }}
                    type="text"
                    placeholder="Recipient's Address"
                    value={destinationAddress}
                    onChange={(e) => setDestinationAddress(e.target.value)}
                  />
                  <div className="flex flex-row justify-center">
                    <button
                      className="flex items-center style2 mr-5"
                      onClick={() => {
                        if (destinationAddress.trim() !== '') {
                          transferItemsToAddress(destinationAddress);
                        } else {
                          alert('Please enter the recipient\'s address.');
                        }
                      }}
                    >
                      Yes, Please
                    </button>
                    <button
                      className="flex items-center style2 ml-5"
                      onClick={() => setShowTransferPopup(false)}>
                      No
                    </button>
                  </div>
                </div>
              </div>
            ) : null}
            <div className="lg:absolute left-0 w-full text-center lg:text-left pl-28 text-container" style={{ zIndex: 1 }}>
              {isLoading ?
                (
                  <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', position: "absolute", top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}>
                    <h2 className="txt-white" style={{ marginBottom: '20px' }}>Fetching Your Items...</h2>
                    <LoadingSVG></LoadingSVG>
                  </div>

                ) : (
                  <><h2 className="txt-white" style={{ marginBottom: '20px' }}>Your In Game Items</h2></>
                )}
              <div style={{
                display: "flex",
                flexWrap: "wrap",
                gap: "10px",
                alignItems: "center",
                maxHeight: '685px',
                overflowY: 'auto',
              }}>
                {assets ? (
                  assets.map((nft, index) => (
                    <div key={index}
                      onClick={() => {
                        if (selected.includes(index)) {
                          const indx = selected.indexOf(index);
                          const clone = [...selected];
                          clone.splice(indx, 1);
                          setSelected(clone);
                        } else {
                          setSelected([...selected, index]);
                        }
                      }}>
                      {selected.includes(index) ? (
                        <NFTCard key={index} nftData={nft} selec={true} />
                      ) : (
                        <NFTCard key={index} nftData={nft} selec={false} />
                      )}
                    </div>
                  ))
                ) : (
                  <div>No NFTs available.</div>
                )}
                <Footer />
              </div>
            </div>

          </div>
        </div >
      }
    </>
  );
};

export default Assets;
