import React from 'react';
import {
    createBrowserRouter,
    RouterProvider, Navigate
} from "react-router-dom";
import Home from '../screens/Home';
import Root from '../components/Root';
import Blog from '../screens/Blog';
import About from '../screens/About';
import Download from '../screens/Download';
import DailyGamers from '../screens/DailyGamers';
import GamerPrizes from '../screens/GamerPrizes';
import Login from '../screens/Login';
import Assets from '../screens/Assets';
// import Shop from '../screens/Shop';
import Pets from '../screens/Pets';
import LootBox from '../screens/LootBox';
import Thankyou from '../screens/Thankyou';
import SuccessPage from '../screens/SuccessPage';
import Leaderboard from '../screens/Leaderboard';
import OpenLootBox from '../screens/OpenLootBox';
import NftDetails from '../screens/NftsDetails';
import { useOneTapProvider } from '../utils/Globals/CustomHooks';
import JoinTournament from '../screens/JoinTournament';
import MrPoshoFrito from '../screens/MrPoshoFrito';
import WombatLogin from '../screens/WombatLogin';
import LoginMobile from '../screens/LoginMobile';
import More from '../screens/More';

interface CustomRouteProps {
    component: React.ComponentType<any>;
    blockedPath: boolean;
    path: string;
}

const CustomRoute: React.FC<CustomRouteProps> = ({ component: Component, blockedPath, path, ...rest }) => {
    if (blockedPath) {
        return <Component {...rest} />;
    } else {
        localStorage.setItem('closedPath', path);
        return <Navigate to="/signin" />;
    }
};

export default function Routes() {
    const { loginMethod, isEthereumConnected, isSphereOneConnected } = useOneTapProvider();
    const accessRoutes = () => {
        if (loginMethod !== "Null" && (isEthereumConnected || isSphereOneConnected)) {
            return true;
        } else {
            return false;
        }
    }
    const router = createBrowserRouter([
        {
            path: "/",
            element: <Root />,
            children: [
                {
                    path: "",
                    element: <Home />
                },
                {
                    path: 'one-tap-beta-release',
                    element: <Blog />
                },
                {
                    path: 'about',
                    element: <About />
                },
                {
                    path: 'signin',
                    element: <Login />
                },
                {
                    path: 'mobile-registration',
                    element: <LoginMobile />
                },
                {
                    path: 'leaderboard/:special',
                    element: <Leaderboard />
                },
                {
                    path: 'more/:page',
                    element: <More />
                },
                {
                    path: 'signup',
                    element: (<div><h2>Signup</h2></div>)
                },
                {
                    path: 'nft-details/:collection/:token_id',
                    element: <NftDetails />
                },
                {
                    path: 'login_wombat/:otid/:method',
                    element: <WombatLogin />
                },
                {
                    path: "assets",
                    element: <CustomRoute component={Assets} blockedPath={accessRoutes()} path="/assets" />
                },
                {
                    path: 'download',
                    element: <Download/>
                },
                {
                    path: 'openlootbox',
                    element: <CustomRoute component={OpenLootBox} blockedPath={accessRoutes()} path="/openlootbox" />
                },
                {
                    path: 'join_tournament',
                    element: <CustomRoute component={JoinTournament} blockedPath={accessRoutes()} path="/join_tournament" />
                },
                {
                    path: 'dailygamers',
                    element: <DailyGamers />
                },
                {
                    path: 'gamerprizes',
                    element: <GamerPrizes />
                },
                {
                    path: 'shop',
                    element: <CustomRoute component={Assets} blockedPath={accessRoutes()} path="/assets" />
                },
                {
                    path: 'pets',
                    element: <Pets />
                },
                {
                    path: 'lootbox',
                    element: <LootBox />
                },
                {
                    path: 'thankyou/:status/:code',
                    element: <Thankyou />
                },
                {
                    path: 'transaction-response/:status/:item',
                    element: <SuccessPage />
                }
            ]
        }
    ])
    return <RouterProvider router={router} />
};